body {
    font-family: $font, Arial, sans-serif;
    font-size: $font-size-3;
    color: $text-black;
}

input {
    display: block;
    color: $input-black;
    background-color: white;
    width: 100%;
    border: 1px solid $border-color;
    border-radius: $small-border-radius;
    padding: $gap-2 $gap-2;
    font-size: $font-size-2;
}

.y-axis-2 {
    display: none;
}

.metrics {
    display: flex;
    border: 1px solid $border-color;
    width: fit-content;
    border-radius: 15px;
    overflow: hidden;

    .metric {
        padding: $gap-1 $gap-2;
        border-right: 1px solid $border-color;
        font-size: $font-size-1;
        font-weight: 300;

        .number {
            font-weight: 800;
            font-size: $font-size-9;
        }
    }

    .metric:last-child {
        border-right: none;
    }

    @media (min-width: 500px) {
        .metric {
            font-size: $font-size-4;
            padding: $gap-2 $gap-4;

            .number {
                font-size: $font-size-12;
            }
        }
    }
}

.highcharts-tooltip {
    font-family: $font;

    .tooltip-x {
        fill: $text-gray;
        font-weight: 300;
        font-size: $font-size-1;
    }
    
    .tooltip-y {
        fill: $text-black;
        font-size: $font-size-5;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

input:disabled {
    opacity: 0.5;
}

a {
    color: $hyperlink-color;
    text-decoration: none;
}

.error {
    color: $error-red;
    font-size: $font-size-1;
}

button {
    cursor: pointer;
    background-color: $black-2;
    color: white;
    padding: $gap-2 $gap-2;
    border-radius: $small-border-radius;
    border: none;
    font-weight: 700;
    width: fit-content;
}