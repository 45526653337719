.activity-page {
    .metrics {
        margin: $gap-4 auto;
    }

    .activity-chart {
        max-width: 800px;
        margin: $gap-6 auto;
        border: 1px solid $border-color;
    }
}