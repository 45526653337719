.users-page {
    .metrics {
        margin: $gap-4 auto;
    }

    .users-list {
        width: fit-content;
        max-width: 100vw;
        margin: $gap-4 auto;
    }

    @media (min-width: 800px) {

        .metrics {
            margin-left: $gap-4;
        }

        .users-panel {
            display: grid;
            grid-template-columns: 300px 1fr;
            width: 100%;
            
            .left-panel {
                height: 100%;

                .users-list {
                    height: 300px;
                    width: fit-content;
                    max-width: 0;
                    margin: 0;
                }
            }

            .right-panel {
                overflow: hidden;
                
                .user-growth-chart {
                    overflow: hidden;
                    width: 100%;
                }
            }
        }
    }
}