$font-size-1: 10px;
$font-size-2: 12px;
$font-size-3: 14px;
$font-size-4: 16px;
$font-size-5: 18px;
$font-size-6: 20px;
$font-size-7: 24px;
$font-size-8: 30px;
$font-size-9: 36px;
$font-size-10: 48px;
$font-size-11: 60px;
$font-size-12: 72px;

$gap-1: 4px;
$gap-2: 8px;
$gap-3: 12px;
$gap-4: 16px;
$gap-5: 24px;
$gap-6: 32px;
$gap-7: 48px;
$gap-8: 64px;
$gap-9: 96px;
$gap-10: 128px;
$gap-11: 192px;
$gap-12: 256px;
$gap-13: 384px;
$gap-14: 512px;
$gap-15: 640px;
$gap-16: 768px;

$small-border-radius: 4px;
$border-radius: 6px;
$large-border-radius: 8px;

$box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
$high-box-shadow: 0 0 0 1px rgba(136, 152, 170, .1), 0 15px 35px 0 rgba(49, 49, 93, .1), 0 5px 15px 0 rgba(0, 0, 0, .08);

$nav-height: 52px;

$dirty-white: #f5f7fa;
$background-gray: #f0f4fa;
$border-color: #cad1d8;
$text-black: #484848;
$text-gray: #747474;
$input-black: #585858;
$black-1: #1c262a;
$black-2: #253338;

$primary: #10ac84;
$error-red: #eb4d4b;
$danger-red: #ca556f;
$hyperlink-color: #5758BB;
$line-chart-color: #34495e;

$font: Muli;
$logo-font: Catamaran;
