* {
    margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
    vertical-align: baseline;
    list-style: none;
    background-color: inherit;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
}