.users-list {
    max-height: 300px;
    overflow-y: scroll;
    border: 1px solid $border-color;
    border-radius: 10px;

    .user {
        padding: $gap-1 $gap-2;

        .email {
            font-size: $font-size-2;
            font-weight: 600;
        }

        .date {
            font-size: $font-size-1;
            font-weight: 300;
            color: $text-gray;
        }
    }

    @media (min-width: 500px) {
        max-height: 400px;

        .user {
            padding: $gap-2 $gap-3;
    
            .email {
                font-size: $font-size-4;
                margin-bottom: $gap-1;
            }
    
            .date {
                font-size: $font-size-2;
            }
        }
    }
}