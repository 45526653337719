nav {
    display: flex;
    border: 2px solid $border-color;
    border-radius: 20px;
    overflow: hidden;
    width: fit-content;
    font-size: $font-size-2;
    margin: $gap-3 auto;
    box-shadow: $box-shadow;

    a, div {
        padding: $gap-2 $gap-3;
        color: $hyperlink-color;
        background-color: $background-gray;
        transition-property: background-color;
        transition-duration: 0.5s;
        cursor: pointer;
        white-space: nowrap;
    }

    a:hover, div:hover, .selected {
        background-color: white;
    }

    a {
        border-right: 1px solid $border-color;
    }

    @media (min-width: 600px) {
        position: absolute;
        top: $gap-3;
        right: $gap-3;
        font-size: $font-size-3;
    }
}